export const environment = {
  production: true,
  appVersion: 'v1.0.1',
  USERDATA_KEY: 'R1Hp2MOuE6Bjt0Jt',
  apiUrl: window.location.origin,
  webUrlLocal:'https://notebook-in.skills30.com',
  webUrlInternational:'https://notebook.skills30.com',
  azStoragePublicUrl: 'https://notebookuat01.blob.core.windows.net/public/',
  azStoragePrivateUrl: 'https://notebookuat01.blob.core.windows.net/private/',
  mobileCountryCode: ['SG','IN','CA'],
  chatGpt: {
    enable : true,
    enableSpeech : true
  },
  socialConfig : {
    google :{
      client_Id : '528688938698-neuavf016l3sdf013gi6nahmc2f1uq9b.apps.googleusercontent.com'
    }
  }
};