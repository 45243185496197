import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, Observable, throwError } from 'rxjs';
import { StorageHelper } from 'src/app/services/lib/storage-helper';
import { environment } from 'src/environments/environment';
import { ApisWithoutLodingScreen, LoaderService } from 'src/app/services/lib/loader-service';
import { AuthService } from '..';
import { UserRoles } from 'src/app/services';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    
    constructor(private storageHelper: StorageHelper,private loaderService:LoaderService) { }
    //dont not show loader for below urls
    apiWithoutLoader : Array<String> = ApisWithoutLodingScreen;

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        var displayLoader = true;
        if(this.apiWithoutLoader.includes(request.url))
            displayLoader = false;
        
        if (displayLoader) 
            this.loaderService.showLoader();
        
        const authToken = this.storageHelper.getAuthToken();
            if (request.url.includes(environment.apiUrl) && authToken && authToken.access_token) {
                request = request.clone({
                    setHeaders: { Authorization: `Bearer ${authToken.access_token}`,'NB-APP-MODE':this.getAppMode() }
                });                    
            }        
        return next.handle(request).pipe(
            map((resp: any) => {
                if (resp instanceof HttpResponse) {
                    if (displayLoader) { this.loaderService.hideLoader(); }
                }
                return resp;
            }),
            catchError((error: HttpErrorResponse) => {
                if (displayLoader) { this.loaderService.hideLoader(); }
                return throwError(() => error);
              })
        );
    }
    getAppMode() : string
    {
        try {
            const lsValue: string | null = localStorage.getItem('nb_app_mode');
            if(lsValue)
                {
                return lsValue;
                }
            } catch (error) {
                    console.error('LOCAL STORAGE PARSE ERROR', error)
            }
        return '';
    }
}