import { Injectable } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { AuthToken, SocialAuthRequest } from '../../models/auth.token';
import { ApiResponse } from 'src/app/models/api-response';
import { LoggedInUser } from '../../models/logged-in-user.model';

const API_USERS_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root',
})

export class AuthHTTPService {

  constructor(private http: HttpClient) { }

  // public methods
  login(username: string, password: string, clientId: string): Observable<any> {

    return this.http.post(`${API_USERS_URL}/api/connect/token`, {
      username,
      password,
      clientId
    });
  }
  async forgotPassword(data: any): Promise<ApiResponse<any>> {
    return await firstValueFrom(this.http.post<ApiResponse<any>>(`${API_USERS_URL}/api/connect/ForgotPassword`, data));
  }

  async tokenVerification(data: any): Promise<ApiResponse<any>> {
    return await firstValueFrom(this.http.post<ApiResponse<any>>(`${API_USERS_URL}/api/connect/TokenVerification/`, data));
  }

  async resetPassword(data: any): Promise<ApiResponse<any>> {
    return await firstValueFrom(this.http.post<ApiResponse<any>>(`${API_USERS_URL}/api/connect/ResetPassword`, data));
  }

  async changePassword(data: any): Promise<ApiResponse<any>> {
    return await firstValueFrom(this.http.post<ApiResponse<any>>(`${API_USERS_URL}/api/connect/TokenVerification/`, data));
  }

  async forcedResetPassword(data: any): Promise<ApiResponse<any>> {
    return await firstValueFrom(this.http.post<ApiResponse<any>>(`${API_USERS_URL}/api/connect/ForcedResetPassword/`, data));
  }

  async TermsAndCondition(): Promise<ApiResponse<any>> {
    return await firstValueFrom(this.http.get<ApiResponse<any>>(`${API_USERS_URL}/api/connect/TermsAndCondition/`));
  }
  async QRLogin(qrData: String): Promise<ApiResponse<String>> {
    return await firstValueFrom(this.http.post<ApiResponse<String>>(`${API_USERS_URL}/api/connect/WebQRLogin`, { otp: qrData }));
  }
  async SocialAuthRequest(data: any): Promise<ApiResponse<SocialAuthRequest>> {
    return await firstValueFrom(this.http.post<ApiResponse<SocialAuthRequest>>(`${API_USERS_URL}/api/connect/SocialAuth/`, data));
  }
  async GetLoginOTP(data: any): Promise<ApiResponse<String>> {
    return await firstValueFrom(this.http.post<ApiResponse<String>>(`${API_USERS_URL}/api/connect/GenerateLoginOTP`, data));
  }


  logout(token: string): Observable<any> | undefined {
    if (token != "") {
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });
      return this.http.post<any>(`${API_USERS_URL}/api/connect/revoke`, {}, { headers: httpHeaders });
    }
  }

  getUserByToken(token: string): Observable<ApiResponse<LoggedInUser>> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.get<ApiResponse<LoggedInUser>>(`${API_USERS_URL}/api/connect/LoggedInUser`, {
      headers: httpHeaders,
    });
  }
  refreshToken(token: AuthToken): Observable<any> {
    return this.http.post(`${API_USERS_URL}/api/connect/token/refresh`, token);
  }

  refreshTokenwithLoginRecordID(token: AuthToken, loginRecordID:string): Observable<any> {
    return this.http.post(`${API_USERS_URL}/api/connect/token/refresh/${loginRecordID}`, token);
  }
  
  async VerifyEmailOrPhone(text: string, type: string): Promise<ApiResponse<any>> {
    return await firstValueFrom(this.http.get<ApiResponse<any>>(`${API_USERS_URL}/api/connect/VerifyEmailOrPhone/${text}/${type}`));
  }

}


