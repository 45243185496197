import { AppSignature } from "src/app/models/app-signature";
import { AssessmentMCQDTO, AssessmentMCQModel } from "../../assessment/models/assessment";
import { VideoType } from "../../resource/models/video.model";
import { ContentType } from "../../topic/models/topic-content.model";
import { Guid } from "guid-typescript";

export class LessonSubject {
  id: string = Guid.EMPTY;
  name: string;
}
export class LessonModule {
  id: string = Guid.EMPTY;
  name: string;
}
export class LessonSubjectTopic {
  subjectID: string = Guid.EMPTY;
  modules: Array<LessonModule> = [];
  contents: Array<TopicAndContent> = [];
}
export class LessonTopic {
  id: string = Guid.EMPTY;
  name: string;
  sortOrder: number;
  parentSubjectID: string;
  parentModuleID: string;
  parentTopicID: string;
  hasAssessment: boolean;
  hasVirtualTrainer: boolean;
}
export class LessonTopicContent {
  id: string = Guid.EMPTY;
  description: string;
  sortOrder: number;
  referenceID: string = Guid.createEmpty().toString();
  type: ContentType;
  parentTopicID: string;
}

export class LessonVideo {
  id: string = Guid.EMPTY;
  name: string;
  url: string;
  duration: number = 0;
  type: VideoType;
  videoID: string;
}

export class LessonDocument {
  id: string = Guid.EMPTY;
  name: string;
}

export class LessonDTO {
  subjects: Array<LessonSubject> = [];
  lessonTopics: Array<LessonSubjectTopic> = [];
}

export class LessonTopicContentDetails {
  topicContent: LessonTopicContent;
  video: LessonVideo;
  document: LessonDocument;
  isCompleted: boolean = false;
}

export class TopicAndContent {
  topic: LessonTopic = new LessonTopic();
  cV: number = 0;
  cN: number = 0;
  cQA: number = 0;
  cA: number = 0;
  contents: Array<LessonTopicContentDetails> = [];
  subTopics: Array<TopicAndContent> = [];
  assessmentMCQs: Array<AssessmentMCQDTO> = [];
}


export class LessonFilter {
  boardID: string = Guid.EMPTY;
  gradeID: string = Guid.EMPTY;
  instituteID: string = Guid.EMPTY;
  userID: string = Guid.EMPTY;
  isReferenceContent: boolean = false;
}


export class ReferenceContentDTO {
  B: LessonBoardDTO1;
  G: LessonGradeDTO1;
  S: Array<LessonSubjectDTO1> = [];
  M: Array<LessonModuleDTO1> = [];
}
export class LessonBoardDTO1 {
  id: string = Guid.EMPTY;
  N: string = '';
}
export class LessonGradeDTO1 {
  id: string = Guid.EMPTY;
  N: string = '';
}
export class LessonSubjectDTO1 {
  id: string = Guid.EMPTY;
  N: string = '';
}
export class LessonModuleDTO1 {
  id: string = Guid.EMPTY;
  sId: string = Guid.EMPTY;
  N: string = '';
}

export class ContentUsage extends AppSignature {
  userID: string;
  topicContentID: string;
  isCompleted: boolean;
  timeSpent: number;
}

export class ContentUsageDTO {
  contentUsage : ContentUsage = new ContentUsage();
}