import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ApiResponse } from 'src/app/models/api-response';
import { ContentUsage, ContentUsageDTO, LessonDTO, LessonFilter, ReferenceContentDTO, TopicAndContent } from '../models/lesson.model';
import { FileTransferModel } from 'src/app/models/file-transfer';
import { WatchedVideoDTO, WatchedVideoModel } from '../../resource/models/video.model';
import { Constants, StorageHelper } from 'src/app/services';

const API_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root',
})

export class ClassHTTPService {
  // activeTopicContentId Subject & Observable
  // on change, emits current active topic contentid to subscribed parties
  activeTopicContentIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>("");
  activeTopicContentId$: Observable<string> = this.activeTopicContentIdSubject.asObservable();

  activeAssessmentIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>("");
  activeAssessmentId$: Observable<string> = this.activeAssessmentIdSubject.asObservable();

  constructor(private http: HttpClient, private storageHelper: StorageHelper) { }
  async GetMyClassLesson(filter: LessonFilter): Promise<ApiResponse<LessonDTO>> {
    var result = await firstValueFrom(this.http.post<ApiResponse<LessonDTO>>(`${API_URL}/api/Lesson/GetMyClassLesson`, filter));
    result.data.lessonTopics.forEach(e => {
      e.contents.forEach(f => {
        f.cV = f.contents.filter(v => v.topicContent.type == 1).length;
        f.cN = f.contents.filter(v => v.topicContent.type == 3).length;
        f.cQA = f.contents.filter(v => v.topicContent.type == 4).length;
        f.cA = f.assessmentMCQs != null ? f.assessmentMCQs.length : 0;
        f.subTopics.forEach(g => {
          f.cV += g.contents.filter(v => v.topicContent.type == 1).length;
          f.cN += g.contents.filter(v => v.topicContent.type == 3).length;
          f.cQA += g.contents.filter(v => v.topicContent.type == 4).length;
          f.cA += g.assessmentMCQs.length;
          g.cV = g.contents.filter(v => v.topicContent.type == 1).length;
          g.cN = g.contents.filter(v => v.topicContent.type == 3).length;
          g.cQA = g.contents.filter(v => v.topicContent.type == 4).length;
          g.cA = g.assessmentMCQs.length;
        })
      })
    })
    this.storageHelper.setMyLesson(result.data);
    return result;
  }
  async GetLessonDocument(documentID: string): Promise<ApiResponse<FileTransferModel>> {
    return await firstValueFrom(this.http.post<ApiResponse<FileTransferModel>>(`${API_URL}/api/Lesson/GetLessonDocument`, { documentID: documentID }));
  }

  async GetWatchedVideo(filter: any): Promise<ApiResponse<WatchedVideoDTO>> {
    return firstValueFrom(this.http.post<ApiResponse<WatchedVideoDTO>>(`${API_URL}/api/WatchedVideo/GetVideoTime`, filter));
  }

  async SaveWatchedVideo(dto: any): Promise<ApiResponse<WatchedVideoModel>> {
    return firstValueFrom(this.http.post<ApiResponse<WatchedVideoModel>>(`${API_URL}/api/WatchedVideo/SaveVideoTime`, dto));
  }

  setActiveTopicContentId(id: string) {
    this.activeTopicContentIdSubject.next(id);
  }
  setActiveAssessmentId(id: string) {
    this.activeAssessmentIdSubject.next(id);
  }
  async GetReferenceContent(filter: LessonFilter): Promise<ApiResponse<Array<ReferenceContentDTO>>> {
    var result = await firstValueFrom(this.http.post<ApiResponse<Array<ReferenceContentDTO>>>(`${API_URL}/api/Lesson/GetReferenceContent`, filter));
    this.storageHelper.setReferenceContent(result.data);
    return result;
  }
  async GetContentUsage(topicContentID: string): Promise<ApiResponse<ContentUsageDTO>> {
    return await firstValueFrom(this.http.get<ApiResponse<ContentUsageDTO>>(`${API_URL}/api/ContentUsage/GetContentUsageForUser/${topicContentID}`));
  }
  async SaveContentUsage(data: any): Promise<ApiResponse<any>> {
    return await firstValueFrom(this.http.post<ApiResponse<any>>(`${API_URL}/api/ContentUsage/Save`, data));
  }
}


