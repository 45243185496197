export const Messages = {
    //Messages
    MESSAGE_FAILED:'Something went wrong, please try again later.',
    MESSAGE_LOGIN_FAILED:'Your login id and password do not match, please try again.',
    MESSAGE_LOGOUT_SESSION_TIMEOUT : 'Your session has expired due to inactivity.<br> Any unsaved changes have been lost.',
    MESSAGE_INVALID_RESET_URL : 'Your password reset link is expired or invalid.<br> Please request for new link.',
    MESSAGE_INVALID_RESET_CODE : 'Your password reset code is expired or invalid.<br> Please request for new code.',
    MESSAGE_INVALID_ACTIVATION_URL : 'Your account acitvation link is expired or invalid.<br>  Please request for new link.',
    MESSAGE_INVALID_ACTIVATION_CODE : 'Your account acitvation code is expired or invalid.<br>  Please request for new code.',
    MESSAGE_INVALID_USER:'Username entered does not match any record.',
    //Messages
    VALIDATION_MANDATORY:'Mandatory field.',
    VALIDATION_EMAIL:'Invalid email format.',
    VALIDATION_LENGTH_MIN:'Minimum length required is #len#.',
    VALIDATION_LENGTH_MAX:'Maximun length allowed is #len#.',
    VALIDATION_PATTERN:'Invalid entry',
    VALIDATION_DUPLICATE:'Duplicate Entry',
    VALIDATION_WEAK:'Password is weak',
    VALIDATION_MEDIUM:'Password is Medium',
    VALIDATION_EMPTY_FIELD:'No entry detected',
    VALIDATION_AMOUNT_EXCEED:'Total milestone amount exceeds contract amount',
    VALIDATION_DOCUMENT_MISSING:'Please upload all documents',
    VALIDATION_TYPE:'Only .jpeg,.jpg,.png and .pdf files are allowed',
    VALIDATION_SIZE:'Maximum upload size is 1 mb',
    VALIDATION_USERNAME_EXISTS:'Username already exists',
    VALIDATION_EMAIL_EXISTS:'Email already in use',
    VALIDATION_PHONE_EXISTS:'Phone number already in use',
    VALIDATION_PASSWORD_DO_NOT_MATCH:'Passwords do not match',
    VALIDATION_VALUE_GREATER_THAN_TATOL_QUESTIONS :'Value cannot be greater than total number of questions'
};