import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription, from } from 'rxjs';
import { map, catchError, switchMap, finalize, first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthHTTPService } from './auth-http/auth-http.service';
import { AuthToken } from '../models/auth.token';
import { StorageHelper } from 'src/app/services/lib/storage-helper';
import { ApiResponse } from 'src/app/models/api-response';
import { LoggedInUser } from '../models/logged-in-user.model';
import { Constants } from 'src/app/services';
import { NgxPermissionsService } from 'ngx-permissions';
import { Guid } from 'guid-typescript';

export type CurrentUser = LoggedInUser | undefined;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {

  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<CurrentUser>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<CurrentUser>;
  isLoadingSubject: BehaviorSubject<boolean>;


  get currentUser(): CurrentUser {
    return this.currentUserSubject.value;
  }

  set currentUser(user: CurrentUser) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router,
    private storageHelper: StorageHelper,
    private readonly permissionsService: NgxPermissionsService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<CurrentUser>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }

  // public methods
  login(username: string, password: string): Observable<any> {
    var self = this;
    this.isLoadingSubject.next(true);
    var webClientName = Constants.Web_Client_id;
    return this.authHttpService.login(username, password, webClientName).pipe(
      map((auth: AuthToken) => {
        const result = this.setAuthToken(auth);
        self.storageHelper.setAuthToken(auth);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        if (err.error.refresh_token == 'UserInactive') {
          return of('UserInactive');
        }
        return of(undefined);
      }),
      finalize(() => {
        self.goToApplication();
        this.isLoadingSubject.next(false);
      })
    );
  }

  logout() {
    const auth = this.getAuthToken();
    localStorage.removeItem(this.authLocalStorageToken);
    this.storageHelper.deleteLocalStorageData();
    this.storageHelper.deleteGenericStorageData();
    this.authHttpService.logout(auth?.access_token ?? "")?.pipe(first()).subscribe();
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
    let intervalId = setInterval(() => {
      if (window.location.href.includes("/auth/login")) {
        clearInterval(intervalId);
      }
      else {
        location.reload();
      }
    }, 2000);
  }

  getUserByToken(): Observable<CurrentUser> {
    const auth = this.getAuthToken();
    if (!auth || !auth.access_token) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.access_token).pipe(
      map((userRes: ApiResponse<LoggedInUser>) => {
        if (userRes) {
          this.currentUserSubject.next(userRes.data);
          this.setPermissions();
        } else {
          this.logout();
        }
        return userRes.data;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  goToApplication() {
    this.router.navigate(['/dashboard']);
  }

  // private methods
  private setAuthToken(auth: AuthToken): boolean {
    if (auth && auth.access_token) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  public getAuthToken(): AuthToken | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }
      const authData = JSON.parse(lsValue) as AuthToken;
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  public refershToken() {
    var self = this;
    var token = self.storageHelper.getAuthToken();
    if (!token)
      return;
    var loginRecordID = this.currentUser?.loggedUserRecordID ?? Guid.EMPTY;
    this.authHttpService.refreshTokenwithLoginRecordID(token, loginRecordID).subscribe(result => {
      try {
        var auth = result as AuthToken
        if (auth?.access_token == '') {
          this.logout();
        }
        else {
          self.setAuthToken(auth);
          self.storageHelper.setAuthToken(auth);
        }
      } catch (error) {
        console.error(error);
        self.logout();
      }
    });
  }

  public autoLogin(token: any, gotoDashboard: boolean) {
    var self = this;
    localStorage.removeItem(this.authLocalStorageToken);
    self.storageHelper.deleteLocalStorageData();
    self.storageHelper.deleteGenericStorageData();
    const result = this.setAuthToken(token);
    self.storageHelper.setAuthToken(token);
    self.getUserByToken()
    if (this.currentUser) {
      if (gotoDashboard) {
        this.goToApplication();
      }
    }
    else {
      location.reload();
    }
  }

  setPermissions() {
    if (this.currentUser?.rolePermissions) {
      var permissions:Array<string> = [];
      this.currentUser.rolePermissions.forEach(element => {
        permissions.push(element.permission)
      });
      this.permissionsService.loadPermissions(permissions);
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
